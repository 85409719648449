export default {
    name: 'details',
    components: {},
    props: [],
    data() {
        return {
        }
    },
    computed: {},
    mounted() {},
    methods: {}
}
