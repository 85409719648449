import TableView from './../../../CRM/Leads/TableView/index';


export default {
  name: 'leads',
  components: {
    TableView
  },
  props: ['agent'],
  data () {
    return {
      searchTerm: '',
      totalRows: 0,
      perPage: 10,
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


