import Utils from '@/helpers/utils';
import Dh from "@/helpers/date.helper";
import HttpClient from "@/helpers/http.client";

import LocationHours from "@/components/Locations/LocationHours/index";
import CustomFilter from "@/components/Filters/Custom/index";

import AgentService from '@/services/agent.service';
import LocationService from '@/services/location.service';

const http = new HttpClient(),
    service = new AgentService(http),
    locationService = new LocationService(http);

export default {
  name: 'AgentListing',

  props: {
    rootCompany: {type: String},
  },

  components: {
    LocationHours,
    CustomFilter
  },

  data() {
    return {
      agents: [],
      allAgents: [],
      agent: {
        id: "",
        FirstName: "",
        LastName: "",
        MiddleName: "",
        Phone: "",
        PhoneType: "WORK",
        Email: "",
        EZMail: "",
        Password: "",
        PasswordConfirm: "",
        Gender: "",
        Role: "",
        Location: "",
        isSeller: false,
        AvatarType: "MALE",
        Address: "",
        AddressType: "WORK",
        State: "FL",
        Country: "US",
        Hours: null,
        notifyOnNewLead: false,
        notifyOnCustomerReq: false,
        notifyOnNewChat: false,
        notifyOnNewWorksheet: false,
        notifyOnNewAppraisal: false,
        notifyOnNewCreditApplication: false,
      },
      agentCopy: {},

      agentRoles: [
        {
          value: 'manager',
          text: this.$t('sales_manager')
        },
        {
          value: 'seller',
          text: this.$t('sales_agent')
        },
        {
          value: 'inventory',
          text: this.$t('inventory_manager')
        },
        {
          value: 'reception',
          text: this.$t('reception_agent')
        },
      ],

      tab: 'user_info',

      gender: [
        {
          value: 'Male',
          text: this.$t('male')
        },
        {
          value: 'Female',
          text: this.$t('female')
        },
        {
          value: 'Other',
          text: this.$t('other')
        }
      ],

      locations: [],
      locationIndex: null,

      createdModal: false,
      transferCustomerModal: {
        shown: false,
        agentOld: {},
        agentNew: {}
      },

      agentOwner: {},

      isTransferringCustomers: false,

      setPassModal: false,
      deleteModal: {
        Shown: false,
        AgentId: '',
      },

      searchTerm: '',
      validation: {},
      errors: {},
      perPage: 25,
      totalRows: 0,
      currentPage: 1,
    }
  },

  computed: {
    company: function () {
      return this.$getUser().permissions.isAdmin ? 'root' : this.$getUser().Company.Name.toLowerCase().replace(/\s/g, '');
    },

    allGrantAccess: {
      get() {
        return this.agent.notifyOnNewLead
            && this.agent.notifyOnCustomerReq
            && this.agent.notifyOnNewChat
            && this.agent.notifyOnNewWorksheet
            && this.agent.notifyOnNewAppraisal
            && this.agent.notifyOnNewCreditApplication;
      },
      set(val) {
        this.agent.notifyOnNewLead = false;
        this.agent.notifyOnCustomerReq = false;
        this.agent.notifyOnNewChat = false;
        this.agent.notifyOnNewWorksheet = false;
        this.agent.notifyOnNewAppraisal = false;
        this.agent.notifyOnNewCreditApplication = false;
        if (val) {
          this.agent.notifyOnNewLead = true;
          this.agent.notifyOnCustomerReq = true;
          this.agent.notifyOnNewChat = true;
          this.agent.notifyOnNewWorksheet = true;
          this.agent.notifyOnNewAppraisal = true;
          this.agent.notifyOnNewCreditApplication = true;
        }
      }
    },

    showGrantAccess() {
      const role = this.agent.Role;
      return role !== "inventory";
    },

    isRootUser() {
      return this.$getUser().permissions.isRoot;
    }
  },

  async mounted() {
    this.agentCopy = {...this.agent};

    await this.list();

    if (!this.isRootUser) {
      await this.getLocations();
      await this.getAllAgents();
    }

    if (this.$getUser().permissions.isAdmin)
      this.agentRoles.unshift(...[
        {
          value: 'admin',
          text: this.$t('company_administrator')
        },
        {
          value: 'bdc',
          text: this.$t('bdc_agent')
        }
      ]);

    if (this.$getUser().permissions.isRoot)
      this.agentRoles.unshift(...[
        {
          value: 'support',
          text: this.$t('support')
        },
        {
          value: 'reseller',
          text: this.$t('reseller')
        },
      ]);
  },

  methods: {
    async resetFormFields() {
      this.agent = {...this.agentCopy};
      this.validation = {};
    },

    async cancelModal() {
      this.createdModal = false;
      await this.resetFormFields();
    },

    async save() {
      const res = await service.create(this.agent);
      if (res && !res.error) {
        this.createdModal = false;
        await this.resetFormFields();
        await this.list();
      } else if (res.error) {
        this.validation = res.data;
      }
    },

    async list(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      const filters = {
        search: this.searchTerm,
        company: this.rootCompany,
        role: this.role
      }

      const res = await service.list(this.perPage, skip, filters);
      if (res && !res.error) {
        this.agents = res.data.agents;
        this.totalRows = res.data.count;
      }
    },

    async getAllAgents() {
      const total = 100;

      const res = await service.list(total);
      if (res && !res.error) {
        this.allAgents = res.data.agents.map(el => {
          return {
            id: el.User._id,
            role: el.Role,
            FullName: `${el.User.FirstName} ${el.User.LastName}`,
            FirstName: el.User.FirstName,
            LastName: el.User.LastName,
          }
        });
      }
    },

    async transferCustomer() {

      this.isTransferringCustomers = true;

      const record = {
        agentOld: this.transferCustomerModal.agentOld,
        agentNew: this.transferCustomerModal.agentNew,
        agentOwner: this.agentOwner,
      };

      const res = await service.transferCustomer(record);
      if (res && !res.error) {
        this.isTransferringCustomers = false;
      }
    },

    async remove(id) {
      const res = await service.delete(id);
      if (res && !res.error) {
        this.agents.length > 1 ? await this.list(this.currentPage) : await this.list();
        this.deleteModal = false;
      } else {
        //TODO: Handle Error
      }
    },

    async setPerPage(num) {
      this.perPage = +(num);
      await this.list();
    },

    async getLocations() {
      const res = await locationService.list();
      if (res && !res.error) {
        this.locations = res.data.locations;
      }
    },

    async setPassword() {

      const res = await service.SetPassword(this.agent.id, this.agent.Password, this.agent.PasswordConfirm);
      if (res && !res.error) {
        this.$notify({
          type: 'success',
          message: res.message
        });
        this.hideSetPassword();
      } else
        this.$notify({
          type: 'error',
          message: res.message
        });
    },

    handlerAgentNew(value) {
      const agent = this.allAgents.find(el => el.id === value);

      const AGENT_ROLE = {
        'bdc': {
          BdcOwner: {_id: agent.id, FirstName: agent.FirstName, LastName: agent.LastName},
        },
        'seller': {
          SellerOwner: {_id: agent.id, FirstName: agent.FirstName, LastName: agent.LastName}
        }
      }

      this.transferCustomerModal.agentNew = {
        id: agent.id,
        FirstName: agent.FirstName,
        LastName: agent.LastName
      };

      this.agentOwner = AGENT_ROLE[agent.role] || {}
    },

    loadTransferCustomerModal(agent) {
      this.transferCustomerModal.shown = true;

      this.transferCustomerModal.agentOld = {
        id: agent.User._id,
        FirstName: agent.User.FirstName,
        LastName: agent.User.LastName,
        role: agent.Role
      }
    },

    handlersAgentsByRole(agent) {
      return this.allAgents.filter(el => el.role === agent.role && el.id !== agent.id);
    },

    setHoursAgent(location) {
      this.locationIndex = this.locations.findIndex(el => el._id === location);
      if (this.locationIndex > -1) {
        const obj = JSON.stringify({...this.locations[this.locationIndex].Hours});
        this.agent.Hours = JSON.parse(obj);
      }

    },

    changeHour(hours) {
      this.agent.Hours = {...hours};
    },

    loadSetPassword(id) {
      this.agent.id = id;
      this.setPassModal = true;
    },

    hideSetPassword() {
      this.agent.id = "";
      this.agent.Password = "";
      this.agent.PasswordConfirm = "";
      this.setPassModal = false;
    },

    highlight(text) {
      return Utils.HighlightText(text, this.searchTerm);
    },

    tooltipDate(date) {
      return Dh.customFormat(date, 'D MMM YYYY, h:mm A');
    },

    async onSelectRole(role) {
      this.role = role;
      await this.list();
    },

    next() {
      this.list(this.currentPage + 1);
    },

    prev() {
      this.list(this.currentPage - 1);
    },
  },

  watch: {
    perPage: {
      handler() {
        this.list();
      }
    },
    searchTerm: {
      async handler() {
        await this.list();
      }
    },

    async createdModal(value) {
      const permissionsIsRoot = this.$getUser().permissions.isRoot

      if (!permissionsIsRoot && value) {
        await this.getLocations();
      }
    }
  },

  filters: {
    agentName(agent) {
      return `${agent.User?.FirstName || ''} ${agent.User?.LastName || ''}`;
    },

    initialLetters(agent) {
      if (agent.User?.FirstName) {
        return agent.User.FirstName.at(0);

      } else if (agent.User?.LastName) {
        return agent.User.LastName.at(0);

      } else {
        return 'A';
      }
    },

    formatDate(date) {
      if (date) {
        return Dh.formatDate(date);
      }
      return 'N/A'
    },
  }
}
