import { render, staticRenderFns } from "./Details.html?vue&type=template&id=2ca68732&scoped=true&external"
import script from "./Details.js?vue&type=script&lang=js&external"
export * from "./Details.js?vue&type=script&lang=js&external"
import style0 from "./Details.scss?vue&type=style&index=0&id=2ca68732&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ca68732",
  null
  
)

export default component.exports