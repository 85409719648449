
import MailService from '@/services/mail.service';

const service = new MailService();

export default {
    name: '',

    components: {   },

    props: [],

    data() {
        return {
            accounts: [],

            account: {
                Email: "",
                Password: "",
                PasswordConfirm: "",
                MailboxQuota: "",
            },

            accountEmail: '',
            accountMailboxQuota: '500',

            accountCopy: {},

            validation: {},

            createModal: false,

            deleteModal: {
                Email: '',
                Shown: false
            },

            perPage: 10,
            totalRows: 0,
            currentPage: 1,
            searchTerm: ''
        }
    },

    computed: {
        company: function () {
            return this.$getUser().Company.Name.toLowerCase().replace(/\s/g, '');
        }
    },

    async mounted() {
        this.list();
    },

    methods: {
        async resetFormFields() {
            this.account = {...this.accountCopy};
            this.validation = {};
        },

        async cancelModal() {
            this.createModal = false;
            this.resetFormFields();
        },

        async create() {
            if (!this.accountEmail || !this.account.Password || !this.account.PasswordConfirm || this.account.Password !== this.account.PasswordConfirm) {
                return;
            }

            this.account.Email = `${this.accountEmail}-${this.company}@mail.ezlead.io`;

            const res = await service.create(this.account);
            if (res && !res.error) {
                await this.list();
                this.createModal = false;
                this.resetFormFields();
            } else if (res.error) {
                this.validation = res.data;
            }
        },

        async remove(email) {

            const res = await service.delete({Email: email});
            if (res && !res.error) {
                this.accounts.length > 1 ? await this.list(this.currentPage) : await this.list();
                this.deleteModal = false;
            }
        },

        async list(page) {
            this.currentPage = page || 1;
            const skip = this.perPage * (this.currentPage - 1);

            const res = await service.list(this.perPage, skip, this.searchVal);
            if (res && !res.error) {
                this.accounts = this.accountCopy = res.data.accounts;
                this.totalRows = res.data.count;
            }
        },

        setPerPage(num) {
            this.perPage = +(num);
            this.list();
        },
    }
}
