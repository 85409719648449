import AgentService from '@/services/agent.service';
import CompanyService from '@/services/company.service';

const service  = new AgentService();
const companyService = new CompanyService();

export default {
    name: 'AgentCreate',
    components: {},
    props:['agentData'],

    data() {
        return {
            agent: {
                FirstName: "",
                LastName: "",
                Phone: "",
                PhoneType: "WORK",
                Email: "",
                EZMail: "",
                Password: "",
                PasswordConfirm: "",
                Gender: "Male",
                Role: "agent",
                AvatarType: "MALE",
                Address: "",
                AddressType: "WORK",
                State: "FL",
                Country: "US"
            },

            agentRoles: [
                {
                    value: 'agent',
                    text: this.$t('sales_agent')
                },
                {
                    value: 'manager',
                    text: this.$t('sales_manager')
                },
                {
                    value: 'inventory',
                    text: this.$t('inventory_manager')
                }
            ],

            EmailsPhoneAddress: [
                {
                    value: 'Work',
                    text: 'Work',
                },
                {
                    value: 'Home',
                    text: 'Home',
                },
                {
                    value: 'Personal',
                    text: 'Personal',
                },
                {
                    value: 'Other',
                    text: 'Other',
                }
            ],
            Gender: [
                {
                    value: 'FEMALE',
                    text: 'Female'
                },
                {
                    value: 'MALE',
                    text: 'Male'
                },
                {
                    value: 'OTHER',
                    text: 'Other'
                }
            ],

            AvatarType: [
                {
                    value: 'FEMALE',
                    text: 'Female'
                },
                {
                    value: 'MALE',
                    text: 'Male'
                },
                {
                    value: 'ABSTRACT',
                    text: 'Abstract'
                }
            ],

            validation: {},

            locations: []
        }
    },
    computed: {
        company: function () {
            return this.$getUser().Company.Name.toLowerCase().replace(/\s/g, '');
        }
    },

    async mounted() {
        if (this.agentData )
            this.agent = this.agentData;

        const res = await companyService.locations();

        if ( res && !res.error ){
            this.locations = res.data;
            if ( res.data.length > 0 )
                this.agent.Location = res.data[0]._id;
        }

        if (this.$getUser().permissions.isAdmin)
            this.agentRoles.unshift(...[
                {
                    value: 'admin',
                    text: this.$t('company_administrator')
                },
                {
                    value: 'bdc',
                    text: this.$t('bdc_operator')
                }
            ]);
        if (this.$getUser().permissions.isRoot)
            this.agentRoles.unshift(...[
                {
                    value: 'support',
                    text: this.$t('support')
                },
                {
                    value: 'reseller',
                    text: this.$t('reseller')
                },
            ]);


    },
    methods: {

        async save() {
            const res = await service.create(this.agent);
            if (res && !res.error) {
                this.back();
            } else if (res.error) {
                this.validation = res.data;
            }
        },

        back() {
            this.$router.push({name: 'agentList'});
        }

    }
}
