import Modal from '@/components/Modal/index.vue';
export default {
  name: 'events',
  components: {Modal},
  props: [],
  data () {
    return {
        createdModal: false,
        events:[],
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
