import _ from 'lodash';
import { bus } from '@/helpers/bus';
import { mapGetters } from 'vuex';
import HttpClient from "@/helpers/http.client";
import config from "@/config";

import States from '@/helpers/states';
import DateHelper from '@/helpers/date.helper';

import AddTags from '@/components/Addtags/index.vue';
import Timeline from '@/components/Timeline/Index/index.vue';
import Notes from '@/components/Notes/index.vue';
import Events from '@/components/Events/index.vue';
import Task from '@/components/Task/index.vue';
import Deals from '@/components/Deals/index.vue';
import Mail from '@/components/Mail/index.vue';
import Calls from '@/components/Calls/index.vue';
import Leads from './../Leads/index';
import Documents from '@/components/Documents/index.vue';
import ImagePicker from '@/components/ImagePicker/index.vue';
import LocationHours from "@/components/Locations/LocationHours/index";

const server_base = config[config.stage].ws_server;

import AgentService from '@/services/agent.service';
import BusinessCardService from '@/services/business.card.service';
// import CompanyService from '@/services/company.service';
import LocationService from '@/services/location.service';

// const companyService = new CompanyService();
const businessCard = new BusinessCardService(http);
const
    http = new HttpClient(),
    service = new AgentService(http),
    locationService = new LocationService(http);

export default {
  name: 'AgentDetails',

  components: {
    ImagePicker,
    AddTags,
    Timeline,
    Notes,
    Events,
    Task,
    Deals,
    Mail,
    Calls,
    Leads,
    Documents,
    LocationHours,
  },

  data() {
    return {
      sectionActive: 'general',
      agent: {
        User: {
          Title: "",
          FirstName: "",
          LastName: "",
          Local: {
            UserEmail: "",
            Password: "",
            SecurityQuestion: "",
            SecurityAnswer: "",
          },
          createdAt: "",

          permissions: {
            isAdmin: false,
            isManager: false,
            isAgent: false,
            isUser: true
          },
          Hours: null,
          CompanyPosition: {
            Department: '',
            Position: ''
          },
          access: {
            listing: {
              unqLead: false,
              chat: false,
              workSheet: false,
              appraisal: false,
              creditApp: false,
              inStoreButton: false,
              maxLockedCustomers: 0,
              afterHorusLeads: {
                value: false,
              },
            },
            notifs: {
              newLead: false,
              customerReq: false,
              newChat: false,
              workSheet: false,
              appraisal: false,
              creditApp: false
            },
            voiceRecords: false,
            myVoiceRecords: false,
            saveAndPushNewLead: false,
            incomingLeads: {
              followUpRules: false,
            }
          },
        },
        Location: "",
        BirthDate: "",
        Gender: "",
        Address: "",
        AddressType: "",
        Photo: "",
        City: "",
        State: "",
        Country: "",
        Zip: "",
        Phone: "",
        PhoneType: "",
        PhoneSystem: "",
        Role: "",
        MaximumChatsQueue: "",
        Password: "",
        PasswordConfirm: "",
        _id: ''
      },
      agentCopy: {},
      userImage: '',

      accessLeadsRules: [{
        active: false,
        type: "Calls",
        value: {
          Duration: 60,
          Total: 10
        }
      }],

      CompanyPositionIndex: {
        show: false,
        index: null
      },
      CompanyPosition: [
        {
          text: 'Sales',
          value: 'sales',
          items: ["Sales Associate", "Sales Associate", "Sales Specialist", "Sales Associate/Product Specialist", "Sales Manager", "Sales Manager/Sales Consultant", "Business Development Center (BDC) Manager", "BDC Customer Care Specialist", "Business Development Representative", "Internet Sales Associate", "Customer Service Representative", "Commercial Sales Manager", "Accessory Sales Person", "Finance and Insurance (F&I) Manager"]
        },
        {
          text: 'Buy',
          value: 'buy',
          items: ["Vehicle Acquisition Specialist", "Vehicle Acquisition Consultant", "Vehicle Acquisition Manager", "Vehicle Acquisition Representative", "Vehicle Acquisition Associate"]
        },
        {
          text: 'Marketing',
          value: 'marketing',
          items: ["Dealership IT Specialist", "Social Media Marketer", "Digital Marketing Manager", "Website Administrator", "Corporate Recruiter", "HR Coordinator"]
        },
        {
          text: 'Office & Administrative',
          value: 'office & administrative',
          items: ["Office Manager/Receptionist", "Executive Assistant", "Administrative Assistant", "Deal Processor", "Accounting Associate", "Accounts Payable/Receivable Clerk", "BillingNav Clerk", "Contract Clerk"]
        },
        {
          text: 'Service & Fixed',
          value: 'service & fixed',
          items: ["Fixed Ops Service Director", "Parts Manager", "Parts Counter Person", "Service Technician", "Service Writer", "Service Manager", "Service Advisor", "Apprentice Tech", "Body Shop Estimator", "Body Shop Technician", "Body Shop Manager", "Detailer and Car Washer", "Quick Lube Service Technician", "Porter", "Automotive Painter", "Automotive Janitor/Custodian", "Collision Center Manager", "Oil Technician", "Tire Technician", "Refinish Technician", "Maintenance Technician", "Wheel Alignment Technician", "Inventory Photographer", "Reconditioning Technician", "Smog Technician"]
        },
        {
          text: 'Leadership',
          value: 'leadership',
          items: ["General Manager", "Controller"]
        },
        {
          text: 'C-Suite',
          value: 'c-suite',
          items: ["Chief Product Officer (CPO)", "Chief Executive Officer (CEO)", "Chief Financial Officer (CFO)", "Chief Information Officer (CIO)", "Chief Operating Officer (COO)", "Chief Marketing Officer (CMO)", "Chief Technology Officer (CTO)"]
        },
      ],

      genders: [
        {
          value: 'Male',
          text: this.$t('male')
        },
        {
          value: 'Female',
          text: this.$t('female')
        },
        {
          value: 'Other',
          text: this.$t('other')
        }
      ],
      roles: [
        { value: 'admin', text: this.$t('admin') },
        { value: 'bdc', text: this.$t('bdc') },
        { value: 'manager', text: this.$t('manager') },
        { value: 'seller', text: this.$t('seller') },
        { value: 'inventory', text: this.$t('inventory') },
        { value: 'reception', text: this.$t('reception') },
      ],
      locations: [],
      locationIndex: null,
      states: [],

      createdModal: false,
      tabComponent: '',
      picker_photo: "",
      errors: {},

      isEditLoading: false,
      isGBCLoading: false,

      reports: {
        agent_activity: ['status_activity_report', 'new_leads_report', 'leads_not_acted_on_report', 'average_speed_to_action_report', 'contact_attempts_report', 'response_rate_report', 'closed_deals_report',],
        call_report: ['call_report', 'call_logs',],
        appointment_report: ['appointment_report',],
        lead_report: ['lead_report',],
        lead_sources: ['source_report', 'speed_to_lead', 'contact_attempts', 'closed_deals_by_source', 'acquisitions_by_source'],
      },
    };
  },

  computed: {
    ...mapGetters([
      'user',
    ]),

    agentAvatar() {
      return this.userImage;
    },

    fullName() {
      if ( !this.agent.User.FirstName && !this.agent.User.LastName ) {
        return 'N/A';
      }

      return this.agent.User.FirstName + ' ' + this.agent.User.LastName;
    },

    fullAddress() {
      if ( !this.agent.Address && !this.agent.City && !this.agent.State && !this.agent.Zip )
        return 'N/A';

      return `${ this.agent.Address || '' } ${ this.agent.City || '' } ${ this.agent.State || '' } ${ this.agent.Zip || '' }`;
    },

    avatarLetters() {
      if ( this.agent.User.FirstName && this.agent.User.LastName ) {
        return this.agent.User.FirstName.charAt(0).toUpperCase() + this.agent.User.LastName.charAt(0).toUpperCase();
      } else if ( this.agent.User.FirstName && !this.agent.User.LastName ) {
        return this.agent.User.FirstName.charAt(0).toUpperCase();
      } else if ( !this.agent.User.FirstName && this.agent.User.LastName ) {
        return this.agent.User.LastName.charAt(0).toUpperCase();
      } else {
        return 'A';
      }
    },

    hours() {
      if ( this.agent && this.agent.User && this.agent.User.Hours )
        return this.agent.User.Hours;
      else
        return this.locations[this.locationIndex].Hours;
    },

    Location() {
      if ( !this.agent.Location ) {
        return 'N/A';
      }
      if ( typeof this.agent.Location === 'object' ) {
        return this.agent.Location.Name;
      }
      if ( typeof this.agent.Location === 'string' ) {
        const selLoc = this.agent.Location;
        const selIndex = this.locations.findIndex(e => e._id === selLoc);
        return this.locations[selIndex].Name;
      }
    },

    getPhoto() {
      return `${ this.$ws_server() }/api/files/download/${ this.agent.Photo }`;
    },

    showDetails() {
      return this.agent && this.agent._id.length > 0;
    },

    /**
     * @return {string}
     */
    NotificationAccess() {
      const arr = [];
      if ( this.agent.User.access.notifs.newLead ) arr.push('new lead');
      if ( this.agent.User.access.notifs.newChat ) arr.push('new chat');
      if ( this.agent.User.access.notifs.customerReq ) arr.push('customer request');
      if ( this.agent.User.access.notifs.workSheet ) arr.push('new worksheet');
      if ( this.agent.User.access.notifs.appraisal ) arr.push('new appraisal');
      if ( this.agent.User.access.notifs.creditApp ) arr.push('new credit application');
      return arr.length ? `Notify on ${ arr.join(', ') }` : 'N/A';
    },

    /**
     * @return {string}
     */
    ListingAccess() {
      const arr = [];
      if ( this.agent.User.access.listing.unqLead ) arr.push('unqualified leads');
      if ( this.agent.User.access.listing.chat ) arr.push('chats');
      if ( this.agent.User.access.listing.workSheet ) arr.push('worksheets');
      if ( this.agent.User.access.listing.appraisal ) arr.push('appraisals');
      if ( this.agent.User.access.listing.creditApp ) arr.push('applications');
      return arr.length ? `Listing ${ arr.join(', ') }` : 'N/A';
    },
  },

  async mounted() {
    await this.getAgent();

    await this.getLocations();

    this.states = States;

    this.userImage = `${ server_base }/api/files/avatar/${ this.agent.User._id }`;

    if ( this.$getUser().permissions.isRoot )
      this.roles.unshift(...[
        {
          value: 'support',
          text: this.$t('support')
        },
        {
          value: 'reseller',
          text: this.$t('reseller')
        },
      ]);

    if ( this.$getUser().permissions.isAdmin )
      this.roles.unshift(...[
        {
          value: 'admin',
          text: this.$t('admin')
        },
        {
          value: 'bdc',
          text: this.$t('bdc')
        }
      ]);
  },

  methods: {
    async getLocations() {
      const res = await locationService.list();
      if ( res && !res.error ) {
        this.locations = res.data.locations;
      }
    },

    async getAgent() {
      const id = this.$route.params.id;
      if ( !id || !id.length > 0 ) {
        return;
      }

      const res = await service.get(id);
      if ( res && !res.error ) {
        this.agent = res.data;
        this.agentCopy = { ...this.agent };

        if ( this.agent.User.CompanyPosition && this.agent.User.CompanyPosition.Department !== 'N/A' ) {
          this.loadCategoryItems(this.agent.User.CompanyPosition.Department);
        }

        const appliedRules = this.agent.leadsAccess;
        if ( appliedRules ) {
          this.accessLeadsRules = [{
            active: true,
            type: 'Calls',
            value: {
              Duration: appliedRules.Calls.Duration,
              Total: appliedRules.Calls.Total
            },
          }];
        }
      }
    },

    async saveAgent() {
      this.isEditLoading = true;

      const agent = Object.assign({ ...this.agent }, { accessLeadsRules: [...this.accessLeadsRules] });
      const res = await service.edit(agent);

      if ( res && !res.error ) {
        this.$notify({ type: 'info', message: this.$t('user_updated') });
        this.agentCopy = { ...this.agent };
        this.errors = {};

      } else {
        this.errors = res?.data || {};
      }

      this.isEditLoading = false;
    },

    async createBusinessCard() {
      this.isGBCLoading = true;

      const res = await businessCard.create(this.agent.User._id);
      if ( res && !res.error ) {
        this.isGBCLoading = false;

        this.$notify({
          type: 'info',
          message: `${ this.$t('business_card_generated') }`
        });
      } else {
        this.isGBCLoading = false;
        this.$notify({
          type: 'error',
          message: `${ res.message }`
        });
      }
    },

    setHoursAgent(location) {
      this.locationIndex = this.locations.findIndex(el => el._id === location);
      this.agentHours = this.locations.find(el => el._id === location);
      if ( !this.agent.User?.Hours ) this.agent.User.Hours = this.agentHours.Hours;
    },

    onUpdateHours(hours) {
      //this.locations[this.locationIndex].Hours = hours;
      this.agent.User.Hours = hours;
    },

    cancelModal() {
      this.$hideModals();
      this.resetAgent();
      this.errors = {};
    },

    resetAgent() {
      this.agent = { ...this.agentCopy };
    },

    formatDate(date) {
      return DateHelper.prettyDate(date);
    },

    async savePhoto() {
      const res = await service.editAvatar(this.agent._id, this.picker_photo);
      if ( res && !res.error ) {
        this.createdModal = false;
        this.agent.Photo = this.picker_photo;
      }
    },

    sendEmail() {
      if ( this.agent.User.Local.UserEmail )
        bus.$emit('SHOW_EMAIL_COMPOSE', { to: this.agent.User.Local.UserEmail });
    },

    sendSMS() {
      if ( this.agent.Phone )
        bus.$emit('SHOW_SMS_MODAL', { to: this.agent.Phone });
    },

    callCustomer() {
      if ( this.agent.Phone )
        this.$call({ To: this.agent.Phone });
    },

    async impersonal() {
      const service = new AgentService();
      const res = await service.Impersonal(this.$route.params.id);
      if ( res && !res.error ) {
        this.$notify({
          type: 'info',
          message: this.$t('system.impersonate')
        });
        setTimeout(async () => {
          const success = await this.$store.dispatch("impersonate", { res });
          if ( success ) {
            this.redirect();
          }
        }, 1000)
      }
    },

    redirect() {
      if ( window.location.pathname === "/dashboard" ) {
        this.$router.go()
      } else {
        this.$router.push({ name: 'Dashboard' })
        this.$router.go()
      }
    },

    loadCategoryItems(value) {
      const index = this.CompanyPosition.findIndex(el => el.value === value);
      if ( index === -1 ) return;

      this.CompanyPositionIndex.show = true;
      this.CompanyPositionIndex.index = index;

    },
  },

  async beforeCreate() {
    bus.$off('IMAGE_PICKER_CHANGE_IMAGE');

    bus.$on('IMAGE_PICKER_CHANGE_IMAGE', data => {
      this.picker_photo = data.Name;
    });
  },

  watch: {
    'agent.User.access.notifs': {
      deep: true,
      handler(notifs) {
        if ( notifs.newLead )
          this.agent.User.access.listing.unqLead = notifs.newLead;

        if ( notifs.newChat )
          this.agent.User.access.listing.chat = notifs.newChat;

        if ( notifs.customerReq )
          this.agent.User.access.listing.customerReq = notifs.customerReq;

        if ( notifs.workSheet )
          this.agent.User.access.listing.workSheet = notifs.workSheet;

        if ( notifs.appraisal )
          this.agent.User.access.listing.appraisal = notifs.appraisal;

        if ( notifs.creditApp )
          this.agent.User.access.listing.creditApp = notifs.creditApp;
      }
    },

    'agent.User.access.listing': {
      deep: true,
      handler(listing) {
        if ( !listing.chat )
          this.agent.User.access.notifs.newChat = listing.chat;

        if ( !listing.unqLead )
          this.agent.User.access.notifs.newLead = listing.unqLead;

        if ( !listing.workSheet )
          this.agent.User.access.notifs.workSheet = listing.workSheet;

        if ( !listing.appraisal )
          this.agent.User.access.notifs.appraisal = listing.appraisal;

        if ( !listing.creditApp )
          this.agent.User.access.notifs.creditApp = listing.creditApp;
      }
    },

    'agent.User.access.reports.agent_activity.items': {
      deep: true,
      handler(obj) {
        let show = false;

        _.forOwn(obj, value => {
          if ( value ) {
            show = true;
          }
        });

        this.agent.User.access.reports.agent_activity.show = show;
      }
    },

    'agent.User.access.reports.call_report.items': {
      deep: true,
      handler(obj) {
        let show = false;

        _.forOwn(obj, value => {
          if ( value ) {
            show = true;
          }
        });

        this.agent.User.access.reports.call_report.show = show;
      }
    },

    'agent.User.access.reports.appointment_report.items': {
      deep: true,
      handler(obj) {
        let show = false;

        _.forOwn(obj, value => {
          if ( value ) {
            show = true
          }
        });

        this.agent.User.access.reports.appointment_report.show = show;
      }
    },

    'agent.User.access.reports.lead_report.items': {
      deep: true,
      handler(obj) {
        let show = false;

        _.forOwn(obj, value => {
          if ( value ) {
            show = true
          }
        });

        this.agent.User.access.reports.lead_report.show = show;
      }
    },

    'agent.User.access.reports.lead_sources.items': {
      deep: true,
      handler(obj) {
        let show = false;

        _.forOwn(obj, value => {
          if ( value ) {
            show = true;
          }
        });

        this.agent.User.access.reports.lead_sources.show = show;
      }
    },
  }
};